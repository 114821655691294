<template>
  <a-modal title="账单明细" width="60%" v-model="visible" :footer="null" :maskClosable="false">
    <a-tabs default-active-key="1">
      <a-tab-pane key="1" tab="账单信息">
        <div class="bill-info">
          <a-form-model ref="searchForm" layout="horizontal" :label-col="{ xs: 1 }" :wrapper-col="{ xs: 18 }">
            <a-divider orientation="left"> 客户信息 </a-divider>
            <a-descriptions :column="3">
              <a-descriptions-item label="大区"> {{ rowData.areaName }} </a-descriptions-item>
              <a-descriptions-item label="事务所"> {{ rowData.firmName }} </a-descriptions-item>
              <a-descriptions-item label="经销商"> {{ rowData.dealerName }} </a-descriptions-item>
            </a-descriptions>
            <a-divider orientation="left"> 账单信息 </a-divider>
            <a-descriptions :column="3">
              <a-descriptions-item label="对账状态">
                <a-badge status="processing" :text="rowData.confirmStatus == 0 ? '未确认' : rowData.confirmStatus == 1 ? '相符' : '不相符'" />
              </a-descriptions-item>
              <a-descriptions-item label="对账时间"> {{ rowData.statementTime }} </a-descriptions-item>
              <a-descriptions-item label="对账人"> {{ rowData.statementUser }} </a-descriptions-item>
            </a-descriptions>
            <a-divider orientation="left"> 余额信息 </a-divider>
            <a-descriptions :column="3">
              <a-descriptions-item label="欠款余额"> ￥{{ rowData.oweAmount | formatMoney }} </a-descriptions-item>
              <a-descriptions-item label="可使用余额"> ￥{{ rowData.totalAmount | formatMoney }} </a-descriptions-item>
              <a-descriptions-item label="资金额度"> ￥{{ rowData.balanceAmount | formatMoney }} </a-descriptions-item>
              <a-descriptions-item label="信用额度"> ￥{{ rowData.limitAmount | formatMoney }} </a-descriptions-item>
            </a-descriptions>
            <br />
          </a-form-model>
        </div>
      </a-tab-pane>
      <a-tab-pane key="2" tab="账户总览">
        <a-row type="flex" justify="center">
          <a-col class="title"><span class="title-company">{{ rowData.dealerName }}</span> 对账单 </a-col>
        </a-row>
        <br />
        <a-row type="flex" justify="space-between">
          <a-col :span="16">客户名称： {{ rowData.dealerName }} </a-col>
          <a-col :span="6">账单周期: {{ rowData.startTime }}~{{ rowData.endTime }}</a-col>
        </a-row>
        <br />
        <a-row type="flex" justify="space-between">
          <a-col class="annotation" type="flex" justify="left">注：上期余额+本期增加-本期发货=本期结余</a-col>
        </a-row>
        <br />
        <!-- 数据表格 -->
        <a-table
          :loading="tableLoading"
          :rowKey="(record) => record.id"
          :columns="ZHZL"
          :data-source="table_ZHZL"
          bordered
          :pagination="false"
        >
          <span slot="QK" slot-scope="text">
            <span class="TextRed">{{ text | formatMoney }}</span>
          </span>
          <span slot="amount" slot-scope="text"> {{ text | formatMoney }} </span>
          <span slot="statementsType" slot-scope="text">
            <a-tag v-if="text == 1">资金</a-tag>
            <a-tag v-if="text == 2">信用额度</a-tag>
            <a-tag v-if="text == 3">合计</a-tag>
          </span>
        </a-table>
      </a-tab-pane>
      <a-tab-pane key="3" tab="资金账户明细">
        <!-- 查询表单 -->
        <a-form-model ref="searchForm" layout="horizontal" :label-col="{ xs: 6 }" :wrapper-col="{ xs: 18 }">
          <a-row :gutter="8">
            <!--            <a-col :md="8" :xl="8" :xxl="8" :sm="12">-->
            <!--              <a-form-model-item label="时间">-->
            <!--                <DateRange :startTime.sync="searchDataZJ.startDate" :endTime.sync="searchDataZJ.endDate"></DateRange>-->
            <!--              </a-form-model-item>-->
            <!--            </a-col>-->
            <a-col :md="8" :xl="8" :xxl="8" :sm="12">
              <a-form-model-item label="交易类型">
                <DictSelect
                  field="dealType"
                  :value.sync="searchDataZJ.dealType"
                  style="width: 100%"
                  placeholder="请选择交易类型"
                ></DictSelect>
                <!--                <a-select :default-value="1">-->
                <!--                  <a-select-option :value="1"> 上期余额 </a-select-option>-->
                <!--                  <a-select-option :value="2"> 承兑（三个月） </a-select-option>-->
                <!--                  <a-select-option :value="3"> 电汇 </a-select-option>-->
                <!--                  <a-select-option :value="4"> 发货 </a-select-option>-->
                <!--                </a-select>-->
              </a-form-model-item>
            </a-col>
            <a-col :md="8" :xl="8" :xxl="8" :sm="12">
              <a-form-model-item label="单号">
                <a-input v-model="searchDataZJ.dealNo" placeholder="请输入单号"></a-input>
              </a-form-model-item>
            </a-col>
            <a-col :xxl="6" :xl="5" :md="8" sm="2">
              <a-form-model-item :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
                <a-button type="primary" @click="getList(1)" icon="search">查询</a-button>
                <a-button type="default" @click="reset(1)" icon="sync">重置</a-button>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
        <a-table
          :columns="columns"
          :data-source="table_ZJ"
          :pagination="pageZJ"
          :rowKey="(record) => record.id"
          bordered
          :maskClosable="false"
          @change="changeTableZJ"
        >
          <span slot="dealType" slot-scope="text">
            <a-tag v-if="text == 1">支付</a-tag>
            <a-tag v-if="text == 2">提现</a-tag>
            <a-tag v-if="text == 3">充值</a-tag>
            <a-tag v-if="text == 4">退款</a-tag>
            <a-tag v-if="text == 5">订单结算</a-tag>
            <a-tag v-if="text == 6">订单取消</a-tag>
            <a-tag v-if="text == 7">提现取消/驳回</a-tag>
            <a-tag v-if="text == 8">订单退款</a-tag>
            <a-tag v-if="text == 9">平台操作</a-tag>
            <a-tag v-if="text == 10">还款</a-tag>
          </span>
          <span slot="dealNo" slot-scope="text, row">
            <a @click="checkOrderInfo(row.dealNo, row.dealType)">{{ text }}</a>
          </span>
          <span slot="amount" slot-scope="text"> {{ text | formatMoney }} </span>
          <span slot="payAmount" slot-scope="text,row">
            <span style="color: red" v-if="row.amountType==2">(-) </span>
            <span style="color: green" v-if="row.amountType==1">(+) </span>
            {{ text | formatMoney }}
          </span>
        </a-table>
        <OrderInfoCheckModal ref="OrderInfoCheckModal"></OrderInfoCheckModal>
        <OrderShipInfoEditModal ref="OrderShipInfoEditModal"></OrderShipInfoEditModal>
        <FinanceReceiptInfoModal ref="FinanceReceiptInfoModal"></FinanceReceiptInfoModal>
      </a-tab-pane>
      <a-tab-pane key="4" tab="信用额度使用明细">
        <!-- 查询表单 -->
        <a-form-model ref="searchForm" layout="horizontal" :label-col="{ xs: 6 }" :wrapper-col="{ xs: 18 }">
          <a-row :gutter="8">
            <!--            <a-col :md="8" :xl="8" :xxl="8" :sm="12">-->
            <!--              <a-form-model-item label="时间">-->
            <!--                <DateRange :startTime.sync="searchDataED.startDate" :endTime.sync="searchDataED.endDate"></DateRange>-->
            <!--              </a-form-model-item>-->
            <!--            </a-col>-->
            <a-col :md="8" :xl="8" :xxl="8" :sm="12">
              <a-form-model-item label="交易类型">
                <DictSelect
                  field="limitDealType"
                  :value.sync="searchDataED.dealType"
                  style="width: 100%"
                  placeholder="请选择交易类型"
                ></DictSelect>
                <!--                <a-select :default-value="1">-->
                <!--                  <a-select-option :value="1"> 上期余额 </a-select-option>-->
                <!--                  <a-select-option :value="2"> 发货 </a-select-option>-->
                <!--                  <a-select-option :value="3"> 新增 </a-select-option>-->
                <!--                </a-select>-->
              </a-form-model-item>
            </a-col>
            <a-col :md="8" :xl="8" :xxl="8" :sm="12">
              <a-form-model-item label="单号">
                <a-input v-model="searchDataED.dealNo" placeholder="请输入单号"></a-input>
              </a-form-model-item>
            </a-col>
            <a-col :xxl="6" :xl="5" :md="8" sm="2">
              <a-form-model-item :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
                <a-button type="primary" @click="getList(2)" icon="search">查询</a-button>
                <a-button type="default" @click="reset(2)" icon="sync">重置</a-button>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
        <a-table
          :columns="XYJE"
          :data-source="tableData"
          :pagination="pageED"
          :rowKey="(record) => record.id"
          bordered
          :maskClosable="false"
          @change="changeTableED"
        >
          <span slot="dealType" slot-scope="text">
            <a-tag color="" v-if="text == 1">支付</a-tag>
            <a-tag color="" v-if="text == 2">充值</a-tag>
            <a-tag color="" v-if="text == 3">订单取消</a-tag>
            <a-tag color="" v-if="text == 4">订单关闭</a-tag>
            <a-tag color="" v-if="text == 5">平台操作</a-tag>
            <a-tag color="" v-if="text == 6">订单修改时退回</a-tag>
            <a-tag color="" v-if="text == 7">订单删除</a-tag>
            <a-tag color="" v-if="text == 8">审核拒绝</a-tag>
            <a-tag color="" v-if="text == 9">额度过期</a-tag>
            <a-tag color="" v-if="text == 10">额度禁用</a-tag>
            <a-tag color="" v-if="text == 11">额度删除</a-tag>
            <a-tag color="" v-if="text == 12">发货单取消</a-tag>
            <a-tag color="" v-if="text == 13">发货单驳回</a-tag>
            <a-tag color="" v-if="text == 14">额度返审</a-tag>
            <a-tag color="" v-if="text == 15">延期充值</a-tag>
            <a-tag color="" v-if="text == 16">还款</a-tag>
          </span>
          <span slot="dealNo" slot-scope="text, row">
            <a @click="checkOrderInfo(row.dealNo, row.dealType)">{{ text }}</a>
          </span>
          <span slot="amount" slot-scope="text"> {{ text | formatMoney }} </span>
          <span slot="payAmount" slot-scope="text,row">
            <span style="color: red" v-if="row.amountType==2">(-) </span>
            <span style="color: green" v-if="row.amountType==1">(+) </span>
            {{ text | formatMoney }}
          </span>
        </a-table>
        <OrderInfoCheckModal ref="OrderInfoCheckModal"></OrderInfoCheckModal>
        <OrderShipInfoEditModal ref="OrderShipInfoEditModal"></OrderShipInfoEditModal>
        <FinanceReceiptInfoModal ref="FinanceReceiptInfoModal"></FinanceReceiptInfoModal>
      </a-tab-pane>
      <a-tab-pane key="5" tab="欠款明细">
        <!--        <div class="QK-info">-->
        <!-- 查询表单 -->
        <a-form-model ref="searchForm" layout="horizontal" :label-col="{ xs: 6 }" :wrapper-col="{ xs: 18 }">
          <a-row :gutter="8">
            <!--              <a-col :md="8" :xl="8" :xxl="8" :sm="12">-->
            <!--                <a-form-model-item label="时间">-->
            <!--                  <DateRange :startTime.sync="searchDataQK.startDate" :endTime.sync="searchDataQK.endDate"></DateRange>-->
            <!--                </a-form-model-item>-->
            <!--              </a-col>-->
            <a-col :md="8" :xl="8" :xxl="8" :sm="12">
              <a-form-model-item label="交易类型">
                <DictSelect
                  field="limitDealType"
                  :value.sync="searchDataQK.dealType"
                  style="width: 100%"
                  placeholder="请选择交易类型"
                ></DictSelect>
                <!--                  <a-select :default-value="1">-->
                <!--                    <a-select-option :value="1"> 上期余额 </a-select-option>-->
                <!--                    <a-select-option :value="2"> 信用额度发货 </a-select-option>-->
                <!--                    <a-select-option :value="3"> 还款 </a-select-option>-->
                <!--                  </a-select>-->
              </a-form-model-item>
            </a-col>
            <a-col :md="8" :xl="8" :xxl="8" :sm="12">
              <a-form-model-item label="单号">
                <a-input v-model="searchDataQK.dealNo" placeholder="请输入单号"></a-input>
              </a-form-model-item>
            </a-col>
            <a-col :xxl="6" :xl="5" :md="8" sm="2">
              <a-form-model-item :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
                <a-button type="primary" @click="getList(3)" icon="search">查询</a-button>
                <a-button type="default" @click="reset(3)" icon="sync">重置</a-button>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
        <a-table
          :columns="QKMX"
          :data-source="table_QKMX"
          :pagination="pageQK"
          :rowKey="(record) => record.id"
          bordered
          :maskClosable="false"
          @change="changeTableQK"
        >
            <span slot="dealType" slot-scope="text">
              <a-tag color="" v-if="text == 1">支付</a-tag>
              <a-tag color="" v-if="text == 2">充值</a-tag>
              <a-tag color="" v-if="text == 3">订单取消</a-tag>
              <a-tag color="" v-if="text == 4">订单关闭</a-tag>
              <a-tag color="" v-if="text == 5">平台操作</a-tag>
              <a-tag color="" v-if="text == 6">订单修改时退回</a-tag>
              <a-tag color="" v-if="text == 7">订单删除</a-tag>
              <a-tag color="" v-if="text == 8">审核拒绝</a-tag>
              <a-tag color="" v-if="text == 9">额度过期</a-tag>
              <a-tag color="" v-if="text == 10">额度禁用</a-tag>
              <a-tag color="" v-if="text == 11">额度删除</a-tag>
              <a-tag color="" v-if="text == 12">发货单取消</a-tag>
              <a-tag color="" v-if="text == 13">发货单驳回</a-tag>
              <a-tag color="" v-if="text == 14">额度返审</a-tag>
              <a-tag color="" v-if="text == 15">延期充值</a-tag>
              <a-tag color="" v-if="text == 16">还款</a-tag>
            </span>
          <span slot="dealNo" slot-scope="text, row">
              <a @click="checkOrderInfo(row.dealNo, row.dealType)">{{ text }}</a>
            </span>
          <span slot="amount" slot-scope="text"> {{ text | formatMoney }} </span>
          <span slot="payAmount" slot-scope="text,row">
              <span style="color: red" v-if="row.amountType==2">(-) </span>
              <span style="color: green" v-if="row.amountType==1">(+) </span>
              {{ text | formatMoney }}
            </span>
        </a-table>
        <OrderInfoCheckModal ref="OrderInfoCheckModal"></OrderInfoCheckModal>
        <OrderShipInfoEditModal ref="OrderShipInfoEditModal"></OrderShipInfoEditModal>
        <FinanceReceiptInfoModal ref="FinanceReceiptInfoModal"></FinanceReceiptInfoModal>
        <!--        </div>-->
      </a-tab-pane>
    </a-tabs>
    <br />
    <div class="footer-btns">
      <a-button type="default" @click="toCancel">关闭</a-button>
    </div>
  </a-modal>
</template>

<script>
import { XYJE, ZJZH, QKMX, ZHZL } from '../thead/quotaColums'
import OrderShipInfoEditModal from '@/views/order_ship_info/components/OrderShipInfoEditModal'
import OrderInfoCheckModal from '@/views/order_info/components/OrderInfoCheckModal'
import FinanceReceiptInfoModal from '@/views/finance_receipt/components/FinanceReceiptInfoModal';
const qs = require('qs')
export default {
  components: {
    OrderInfoCheckModal,
    OrderShipInfoEditModal,
    FinanceReceiptInfoModal,
  },
  data() {
    return {
      visible: false,
      rowData: {},
      XYJE: XYJE,
      columns: ZJZH,
      QKMX: QKMX,
      ZHZL: ZHZL,
      table_ZHZL: [],
      table_ZJ: [],
      tableData: [],
      table_QKMX: [],
      searchDataZJ: {}, // 资金搜索条件
      searchDataED: {}, // 额度搜索条件
      searchDataQK: {}, // 欠款搜索条件
      scroll: { y: 600 },
      tableLoading: false, //表格loading
      pageZJ: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        showTotal: () => `共${this.pageZJ.total}条`,
      },
      pageED: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        showTotal: () => `共${this.pageED.total}条`,
      },
      pageQK: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        showTotal: () => `共${this.pageQK.total}条`,
      },
      selectedRowKeys: [],
      baseUrl: process.env.VUE_APP_API_BASE_URL,
      selectedRows: [],
      dealerId: 0,
      statementsId: 0
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.table_ZJ = []
      this.table_QKMX = []
      this.table_ZHZL = []
      this.tableData = []
    },
    toCancel() {
      this.visible = false
      this.$refs.form.resetFields()
    },
    // 查询
    getList(type) {
      if (type == 1) {
        this.pageZJ.current = 1
        this.pageZJ.pageSize = 10
        this.getDataZJ()
      }else if (type == 2) {
        this.pageED.current = 1
        this.pageED.pageSize = 10
        this.getDataED()
      }else if (type == 3) {
        this.pageQK.current = 1
        this.pageQK.pageSize = 10
        this.getDataQK()
      }else {
        this.getData()
      }
    },

    // 重置
    reset(type) {
      this.regionalOffice = []
      if (type == 1) {
        this.pageZJ.current = 1
        this.pageZJ.pageSize = 10
        this.searchDataZJ = {}
        this.getDataZJ()
      }else if (type == 2) {
        this.pageED.current = 1
        this.pageED.pageSize = 10
        this.searchDataED = {}
        this.getDataED()
      }else if (type == 3) {
        this.pageQK.current = 1
        this.pageQK.pageSize = 10
        this.searchDataQK = {}
        this.getDataQK()
      }else {
        this.getData()
      }
    },
    //打开上游单据
    checkOrderInfo(orderCode, dealType) {
      console.log(orderCode, dealType)
      const data = {
        orderCode: orderCode,
      }
      if (dealType == 3) {
        this.$refs.FinanceReceiptInfoModal.setRowDataByCode(orderCode, 'check')
      } else {
        if (orderCode.indexOf('DO') !== -1) {
          this.$refs.OrderShipInfoEditModal.setRowDataByCode(data, 'check')
        } else {
          this.$refs.OrderInfoCheckModal.isShowByOrderCode(data, 'check')
        }
      }
    },
    setRowData(row) {
      this.visible = true
      this.rowData = row
      this.dealerId = row.dealerId
      this.statementsId = row.id
      this.getData()
      this.getDataZJ()
      this.getDataED()
      this.getDataQK()
    },
    /**
     * 更改分页
     */
    changeTableZJ(pagination) {
      this.pageZJ = pagination
      this.getDataZJ()
    },
    changeTableED(pagination) {
      this.pageED = pagination
      this.getDataED()
    },
    changeTableQK(pagination) {
      this.pageQK = pagination
      this.getDataQK()
    },

    //账户
    getData() {
      let params = {
        statementsId: this.statementsId,
      }
      const postData = Object.assign(params)
      this.axios
        .get(`/api/order/statements/dealerStatementsDetail/list?${qs.stringify(postData, {arrayFormat: 'repeat',})}`)
        .then((res) => {
          if (res.code === 200) {
            const { total, records } = res.body
            // this.page.total = total
            this.table_ZHZL = records
            const table = {};
            table.statementsType = 3;
            this.table_ZHZL.forEach((item) => {
              //遍历并累加
              table.oweAmount = table.oweAmount || 0 + item.oweAmount;
              table.addAmount = table.addAmount || 0 + item.addAmount;
              table.lastMonthAmount = table.lastMonthAmount || 0 + item.lastMonthAmount;
              table.shipAmount = table.shipAmount || 0 + item.shipAmount;
              table.efficacyAmount = table.efficacyAmount || 0 + item.efficacyAmount;
              table.canUseAmount = table.canUseAmount || 0 + item.canUseAmount;
            });
            this.table_ZHZL.push(table)
          }
        })
    },

    //资金
    getDataZJ() {
      let params = {
        dealerId: this.dealerId,
        startDate: this.rowData.startTime,
        endDate: this.rowData.endTime,
        pageSize: this.pageZJ.pageSize,
        pageNumber: this.pageZJ.current,
      }
      const postDataZJ = Object.assign(params, this.searchDataZJ)
      this.axios
        .get(`/api/dealer/dealer/dealerPayRecord/selectPayRecordByDealer?${qs.stringify(postDataZJ, {arrayFormat: 'repeat',})}`)
        .then((res) => {
          if (res.code === 200) {
            const { total, records } = res.body
            this.pageZJ.total = total
            this.table_ZJ = records
          }
        })
    },

    //额度
    getDataED() {
      let params = {
        dealerId: this.dealerId,
        startDate: this.rowData.startTime,
        endDate: this.rowData.endTime,
        pageSize: this.pageED.pageSize,
        pageNumber: this.pageED.current,
      }
      const postDataED = Object.assign(params, this.searchDataED)
      this.axios
        .get(`/api/dealer/limit/limitInfoPayRecord/list?${qs.stringify(postDataED, {arrayFormat: 'repeat',})}`)
        .then((res) => {
          if (res.code === 200) {
            const { total, records } = res.body
            this.pageED.total = total
            this.tableData = records
          }
        })
    },

    //欠款
    getDataQK() {
      let params = {
        dealerId: this.dealerId,
        startDate: this.rowData.startTime,
        endDate: this.rowData.endTime,
        pageSize: this.pageQK.pageSize,
        pageNumber: this.pageQK.current,
      }
      const postDataQK = Object.assign(params, this.searchDataQK)
      this.axios
        .get(`/api/dealer/limit/limitInfoPayRecord/list?${qs.stringify(postDataQK, {arrayFormat: 'repeat',})}`)
        .then((res) => {
          if (res.code === 200) {
            const { total, records } = res.body
            this.pageQK.total = total
            this.table_QKMX = records
          }
        })
    },
  },
}
</script>

<style lang="less" scoped>
.footer-btns {
  display: flex;
  justify-content: flex-end;
}
::v-deep .ant-descriptions-row .ant-descriptions-item .ant-descriptions-item-label {
  width: 6.25rem;
  text-align: right;
}
// 账单总览
.footer-btns {
  text-align: right;
}
.title {
  font-size: 1rem;
  font-weight: 900;
  .title-company {
    border-bottom: 1px double black;
    cursor: pointer;
  }
}
::v-deep .ant-table-thead > tr > th.headerYellow {
  background-color: #fff2cc;
}
::v-deep .ant-table-thead > tr > th.headerBlue {
  background-color: #ddebf7;
}
::v-deep .ant-table-thead > tr > th.headerTextRed {
  color: red;
  font-weight: 600;
}
.TextRed {
  color: red;
}
.annotation {
  color: orange;
  font-size: 12px;
}
</style>
